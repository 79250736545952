.page-default-header .header-container {
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    max-width: 100%;
    min-height: 30rem;
}

.page-default-header .header-container a {
    color: var(--color-light);
    text-decoration: none;
}

@media (orientation: portrait) {
    .page-default-header .header-container {
        min-height: 33vh;
    }
}

@media (orientation: landscape) {
    .page-default-header .header-container {
        min-height: 30rem;
    }
}

@media (orientation: portrait) and (max-width: 1080px) {
    .page-default-header .header-container {
        justify-content: flex-end;
        min-height: 33vh;
        padding-bottom: 1.5rem;
    }
}

